<template>
  <!-- start preloader -->
  <!--div class="preloader">
    <div class="sk-spinner sk-spinner-rotating-plane"></div>
  </div-->
  <!-- end preloader -->

  <!-- start home -->
  <section id="home" v-if="page==0">
    <div class="overlay">
      <div class="container">
        <div class="row">
          <div class="col-md-1"></div>
          <div class="col-md-10 wow fadeIn" data-wow-delay="0.3s">
            <h1 class="text-upper mobile-title">Модуль бронирования на сайт сауны, игровой комнаты, квест студии,
              пейнтбольного клуба, боулинга
            </h1>
            <h1 class="text-upper fade-title">Модуль бронирования для Вашего сайта
              <transition name="fade">
                <div :key="quote">{{ quote }}</div>
              </transition>
            </h1>
            <!--p class="tm-white">Увеличение продаж за счет бронирования клиентами через модуль на сайте.
              <br>Клиент будет выбирать дату, свободные номера и удобное для него время. Повышение удобства
              использования вашего сайта будет вашим преимуществом.</p-->

            <!--img src="images/software-img.png" class="img-responsive" alt="home img"-->

            <img src="https://bookingeasy.ru/images/g2092.png" class="img-responsive" alt="home img">

          </div>
          <div class="col-md-1"></div>
        </div>
      </div>
    </div>
  </section>
  <!-- end home -->


  <!-- start divider -->
  <section id="divider" v-if="page==0">
    <div class="container">
      <div class="row">
        <div class="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
          <div class="divider-image-container">
            <div class="divider-image i-1"></div>
          </div>
          <h3 class="text-uppercase">Увеличение продаж</h3>
          <p>Многим клиентам удобнее посмотреть и забронировать на сайте, установка модуля повысит вашу привлекательность для них</p>
        </div>
        <div class="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
          <div class="divider-image-container">
            <div class="divider-image i-2"></div>
          </div>
          <h3 class="text-uppercase">Бронирования 24/7</h3>
          <p>Ваши клиенты увидят информацию о свободных датах и времени, ценах, номерах и смогут забронировать свободный
            номер в любое время суток.</p>
        </div>
        <div class="col-md-4 wow fadeInUp templatemo-box" data-wow-delay="0.3s">
          <div class="divider-image-container">
            <div class="divider-image i-3"></div>
          </div>
          <h3 class="text-uppercase">Прямые платежи без комиссии</h3>
          <p>Денежные средства поступают вам напрямую.</p>
        </div>
      </div>
    </div>
  </section>
  <!-- end divider -->

  <!-- start feature -->
  <section id="feature" v-if="page==0">
    <div class="container">
      <div class="row">
        <div class="col-md-6 wow fadeInLeft" data-wow-delay="0.6s">
          <h2 class="text-uppercase">Возможности</h2>
          <p><b>Мгновенные уведомления в Телеграм: </b>Будьте на шаг впереди с быстрым реагированием на новые бронирования!</p>
          <p><b>Легкая интеграция: </b>Установите модуль на свой сайт всего за 5-10 минут и начните получать заказы!</p>
          <p><b>Индивидуальный стиль: </b>Настройте внешний вид под свой бренд и привлекайте клиентов!</p>
          <p><b>Без комиссий: </b>Получайте 100% прибыли от каждого бронирования без лишних затрат!</p>
          <p><b>Адаптивный дизайн: </b>Модуль отлично работает на телефонах, планшетах и других устройствах, обеспечивая удобство для ваших клиентов.</p>
          <p><b>Удобный поиск и фильтрация: </b>Легко находите, сортируйте и фильтруйте заказы и клиентов — всё под контролем!</p>
        </div>
        <div class="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
          <h2 class="text-uppercase">Режимы работы модуля</h2>
          <p><b>Режим "Просмотр": </b>Ваши клиенты видят все доступные номера – свободные и занятые – в удобном формате. Это повышает прозрачность, удобство и вовлекает их в процесс выбора.</p>
          <p><b>Режим "Бронирование": </b>Ваши клиенты видят все доступные номера и бронируют их, вы получайте мгновенные уведомления в Telegram о новых заявках! Оперативно связывайтесь с клиентами и предлагайте удобные способы оплаты вне системы. Либо настройте автоматическое подтверждение бронирования для максимальной скорости!</p>
          <p><b>Режим "Бронирование с оплатой": </b>Клиенты бронируют, оплачивают и моментально резервируют номер. Вы получаете уведомление в Telegram и гарантированную бронь. Больше никаких упущенных возможностей!</p>
        </div>

        <!--div class="col-md-6 wow fadeInRight" data-wow-delay="0.6s">
          <img src="images/software-img2.png" class="img-responsive">
        </div-->
      </div>
    </div>
  </section>
  <!-- end feature -->

  <!-- start feature1 -->
  <!--section id="feature1" v-if="page==0">
    <div class="container">
      <div class="row">
        <div class="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
          <img src="images/software-img3.png" class="img-responsive">
        </div>
        <div class="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
          <h2 class="text-uppercase">Режимы работы модуля</h2>
          <p>Клиенты только смотрят свободные и занятые номера</p>
          <p>Клиенты оставляют заявку, вам приходит уведомление в Телеграм, оператор связывается и оплата вне
            системы</p>
          <p>Клиент оплачивает и резервирует за собой номер и время, вам приходит уведомление в Телеграм</p>
        </div>
      </div>
    </div>
  </section-->
  <!-- end feature1 -->




  <!-- start download -->
  <section id="download" v-if="page==0">
    <div class="container">
      <div class="row">
        <!--div class="col-md-12 wow bounceIn">
          <h2 class="text-uppercase">Примеры</h2>
        </div-->
        <div class="col-md-12 wow" data-wow-delay="0.6s">
          <h4 class="text-uppercase">Пример виджета встроенного в ваш сайт</h4><br>
          <booking-widget></booking-widget>
          <br><br>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 wow" data-wow-delay="0.6s">
          <h4 class="text-uppercase">Пример с переходом на персональную страницу после нажатия на кнопку/ссылку</h4>
          <input class="btn btn-primary main-btn text-uppercase"
                 onclick="return location.href = 'https://bookingeasy.ru/api?key=dsfsd43dsfgjfj465'"
                 value="Забронировать">
          <br><br><br><br>
        </div>
      </div>


    </div>
  </section>
  <!-- end download -->


  <!-- start pricing -->
  <section id="pricing" v-if="page==0">
    <div class="container">
      <div class="row">
        <div class="col-md-12 wow bounceIn">
          <h2 class="text-uppercase">Наши цены</h2>
        </div>
        <div class="col-md-4 wow fadeIn" data-wow-delay="0.6s">
          <div class="pricing text-uppercase">
            <div class="pricing-title">
              <h4>Первый месяц</h4>
              <p>Бесплатно</p>
            </div>
            <button class="btn btn-primary main-btn text-uppercase" @click="logout(); $router.push('/login/2');">
              Подключить
            </button>
          </div>
        </div>
        <div class="col-md-4 wow fadeIn" data-wow-delay="0.6s">
          <div class="pricing active text-uppercase">
            <div class="pricing-title">
              <h4>1 год</h4>
              <p>9900 <small class="text-lowercase">&#x20bd;</small></p>
            </div>
            <button class="btn btn-primary main-btn text-uppercase" @click="logout(); $router.push('/login/2');">
              Подключить
            </button>
          </div>
        </div>
        <div class="col-md-4 wow fadeIn" data-wow-delay="0.6s">
          <div class="pricing text-uppercase">
            <div class="pricing-title">
              <h4>1 месяц</h4>
              <p>990 <small class="text-lowercase">&#x20bd;</small></p>
            </div>
            <button class="btn btn-primary main-btn text-uppercase" @click="logout(); $router.push('/login/2');">
              Подключить
            </button>
          </div>
        </div>
      </div>
    </div>
    <br>
    <div>У нас нет комиссий за бронирование или еще каких-либо скрытых платежей</div>
  </section>
  <!-- end pricing -->


  <!-- start faq -->
  <section id="faq" v-if="page==0">
    <div class="container">
      <div class="row">
        <div class="col-md-12 wow bounceIn">
          <h2 class="text-uppercase">Ответы на вопросы</h2>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 wow fadeIn" data-wow-delay="0.6s">
          <div class="faq">
            <div v-for="(f, index) in faq" :key="index" class="faq-page"
                 @click="faqIndex==index?faqIndex=-1:faqIndex=index">
              <h4>{{ f.title }}</h4>
              <p v-if="faqIndex==index" v-html="f.text"></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end faq -->


  <!-- start contact -->
  <section id="contact" v-if="page==0">
    <div class="overlay">
      <div class="container">
        <div class="row">
          <div class="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
            <h2 class="text-uppercase">Контакты</h2>
            <p>Вы можете связаться с нами по любому вопросу, в том числе и вопросу подключения, используя телефон, email
              или форму. По номеру телефона доступен Telegram, Whatsapp, Viber.</p>
            <address>
              <!--p><i class="fa fa-map-marker"></i>Родионова, Нижний Новгород, Россия</p-->
              <p><i class="fa fa-phone"></i><a href="tel:+79107948484">+7 910 794-84-84</a></p>
              <p><i class="fa fa-envelope-o"></i><a href="mailto:info@bookingeasy.ru">info@bookingeasy.ru</a></p>
            </address>
          </div>
          <div class="col-md-6 wow fadeInUp" data-wow-delay="0.6s">
            <div id="sitecontact" class="contact-form">
              <form>
                <div class="col-md-12">
                  {{ formFields.error }}<br><br>
                </div>
                <div class="col-md-6">
                  <input v-model="formFields.name" type="text" class="form-control" placeholder="Имя">
                </div>
                <div class="col-md-6">
                  <input v-model="formFields.email" type="email" class="form-control" placeholder="Email или телефон">
                </div>
                <div class="col-md-12">
                  <input v-model="formFields.topic" type="text" class="form-control" placeholder="Тема">
                </div>
                <div class="col-md-12">
                  <textarea v-model="formFields.text" class="form-control" placeholder="Сообщение" rows="4"></textarea>
                </div>
                <div class="col-md-12">
                  <div class="booking-widget-agree">
                    <input type="checkbox" v-model="formFields.agreeValue">
                    <router-link to="/agreement2">
                      <div class="booking-widget-agree-a">Я даю согласие на обработку персональных данных</div>
                    </router-link>
                    <br><br>
                  </div>
                </div>
                <div class="col-md-8">
                  <input @click="sendContact()" type="button" class="btn btn-primary main-btn text-uppercase"
                         value="Отправить">
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- end contact -->


  <!-- start footer -->
  <footer>
    <div class="container">
      <div class="row">
        <ul class="nav navbar-nav navbar-right text-uppercase">
          <!--li><router-link to="/" @click="page=14">Помощь</router-link></li-->

          <li>
            <router-link to="/agreement1">Договор-оферта</router-link>
          </li>
          <li>
            <router-link to="/agreement3">Политика конфиденциальности</router-link>
          </li>
          <li v-if="!global.authenticated">
            <router-link to="/orders" @click="page=11">Вход/Регистрация</router-link>
          </li>

        </ul>
      </div>

      <div class="row">
        <p>Copyright © 2023 BookingEasy</p>
      </div>
    </div>
  </footer>
  <!-- end footer -->


</template>

<style>


/* start navigation */

/* end navigation */

/* start login */

/* end login */

/* start orders */

/* end orders */

/* start lk */

/* end lk */

/* start help */

/* end help */

/* start balance */

/* end balance */

/* start home */
#home {
  background: url('../images/home-bg.jpg') no-repeat;
  background-size: cover;
  color: #fff;
  text-align: center;
  width: 100%;
}

#home p {
  font-weight: 400;
  font-style: italic;
  line-height: 2em;
}

#home img {
  display: inline-block;
  margin-top: 30px;
}

#home .overlay {
  padding-bottom: 0px;
}

/* end home */


/* start divider */
#divider {
  background: #f8f8f8;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

#divider .fa {
  color: #28a7e9;
  font-size: 60px;
}

#divider h3 {
  font-size: 20px;
}

#divider .divider-image-container {
  display: flex;
  justify-content: center;
}

#divider .divider-image {
  width: 80px;
  height: 80px;
  background-size: 100%;
  background-repeat: no-repeat;
}

#divider .i-1 {
  background-image: url("../images/10.png");
}

#divider .i-2 {
  background-image: url("../images/20.png");
}

#divider .i-3 {
  background-image: url("../images/30.png");
}

/* end divider */

/* start feature */
#feature {
  /*background: #f8f8f8;*/
  padding-top: 80px;
  padding-bottom: 80px;
}

#feature p {
  padding-top: 10px;
}

#feature span {
  float: left;
}

#feature .fa {
  background: #28a7e9;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 20px;
}

/* end feature */

/* start feature1 */
#feature1 {
  padding-top: 60px;
  padding-bottom: 60px;
}

#feature1 p {
  padding-top: 10px;
}

#feature1 span {
  float: left;
}

#feature1 .fa {
  background: #28a7e9;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-right: 20px;
}

/* end feature1 */



/* start download */
#download {
  background: #f8f8f8;
  padding-top: 40px;
  padding-bottom: 80px;
}

#download h2 {
  padding-bottom: 20px;
  text-align: center;
}

#download .btn {
  font-weight: bold;
  margin-top: 20px;
  padding: 10px 40px 10px 40px;
}

/* end download */


/* start pricing */
#pricing {
  background: transparent;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

#pricing h2 {
  padding-bottom: 60px;
}

#pricing .pricing {
  background: #fff;
  border-radius: 28px;
  border: 1px solid #28a7e9;
}

#pricing .active {
  position: relative;
  bottom: 20px;
}

#pricing .pricing .pricing-title {
  background: #28a7e9;
  color: #fff;
  font-weight: bold;
  padding: 30px;
  border-top-left-radius: 28px;
  border-top-right-radius: 28px;
}

#pricing .pricing .pricing-title p {
  font-size: 20px;
}

#pricing .pricing ul {
  padding: 0;
  margin: 0;
}

#pricing .pricing ul li {
  display: block;
  list-style: none;
  padding: 16px;
}

#pricing .pricing .btn {
  background: #f8f8f8;
  border: 1px solid #28a7e9;
  color: #28a7e9;
  font-weight: bold;
  padding-right: 40px;
  padding-left: 40px;
  margin-top: 20px;
  margin-bottom: 30px;
  transition: all 0.3s ease;
}

#pricing .pricing .btn:hover {
  background: #28a7e9;
  color: #fff;
}

/* end pricing */



/* start faq */
#faq {
  background: #f8f8f8;
  text-align: center;
  padding-top: 80px;
  padding-bottom: 80px;
}

#faq h2 {
  padding-bottom: 40px;
}

.faq {
  background: #fff;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.12);
  width: 100%;
  border-radius: 28px;
}

.faq-page {
  padding: 1px 16px;
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: rgba(0, 0, 0, 0.12);
  text-align: left;
  cursor: pointer;
}

.faq-page:first-child {
  border-top: none;
}

/* end faq */

/* start contact */
#contact {
  background: url('../images/contact-bg.jpg') no-repeat;
  background-size: cover;
  color: #fff;
}

#contact h2 {
  padding-bottom: 10px;
}

#contact address {
  padding-top: 20px;
}

#contact address .fa {
  display: inline-block;
  background: #28a7e9;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin-top: 6px;
  margin-right: 10px;
}

#contact .contact-form {
  padding-top: 40px;
}

#contact .form-control {
  border: none;
  border-radius: 0px;
  box-shadow: none;
  margin-bottom: 20px;
}

#contact input {
  height: 50px;
}

#contact input[type="button"] {
  background: #28a7e9;
  color: #fff;
  font-weight: bold;
  transition: all 0.3s ease;
}

#contact input[type="button"]:hover {
  background: #107DB6;
}

.booking-widget-agree {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.booking-widget-agree input {
  background-color: #28a7e9;
  accent-color: #28a7e9;
  margin-top: 0px;
  margin-right: 8px;
  width: 25px;
  height: 25px;
}

.booking-widget-agree-a {
  color: #fff;
  text-decoration: underline;
  cursor: pointer;
}

.booking-widget-agree-a:hover,
.booking-widget-agree-a:focus,
.booking-widget-agree-a.current {
  color: #fff;
}

#contact .main-btn {
  width: 200px;
}

#contact a{
  color: #fff;
  text-decoration: none;
}
#contact a:hover{
  color: #fff;
  text-decoration: none;
}

/* end contact */

/* start footer */
footer {
  background: #fff;
  font-weight: 400;
  text-align: center;
  padding: 20px;
}

/* end footer */

@media screen and ( max-width: 991px ) {
  .templatemo-box {
    margin-bottom: 30px;
  }

  #pricing .active {
    bottom: 0;
  }

  .pricing {
    margin-bottom: 20px;
  }

  #feature img, #download img {
    margin-top: 30px;
  }

  #feature1 img {
    margin-bottom: 30px;
  }

  .mobile-title {
    display: block !important;
  }

  .fade-title {
    display: none;
  }
}

@media screen and ( max-width: 767px ) {
  .navbar-default .nav li a {
    height: auto;
    line-height: 2em;
  }

  #feature, #pricing, #download {
    padding-top: 20px;
    padding-bottom: 40px;
  }

  #contact .overlay {
    padding-top: 40px;
  }
}

/* start media 360 */
@media screen and ( max-width: 360px ) {
  .pricing {
    padding-bottom: 40px;
  }
}

/* end media 360 */

.mobile-title {
  display: none;
}

.fade-title {
  height: 80px;
}

.fade-enter-active {
  transition: all 1.5s ease-out;
}

.fade-leave-active {
  transition: all 1.5s ease-out;
}

.fade-enter-from, .fade-leave-to {
  opacity: 0;
}


</style>


<script>
import axios from 'axios'
//import Toast from '@/js/toast.min.js'
import {global} from "@/global";






export default {
  name: 'HomeView',
  data() {
    return {
      global: global,
      page: 0,
      formFields: {name: "", email: "", topic: "", text: "", agreeValue: false},
      result: {},
      errored: false,
      loading: false,
      quoteIndex: 0,
      quote: "сауны",
      quotes: ["сауны", "игровой комнаты", "квест студии", "пейнтбольного клуба", "боулинга"],
      faq: [
        {
          "title": "Какой порядок действий для подключения",
          "text": "1. Пройти регистрацию на сайте. Далее необходимо подтвердить e-mail, указанный при регистрации. <br>2. Нужно настроить систему, а также разместить группу объектов (баню, сауну и т.д), добавив номера. Виджет готов к установке. <br>3. Установить виджет на сайт или добавить ссылку в социальные сети. В разделе \"Помощь\" находится ссылка для использования виджета вне вашего сайта, также размещена инструкция для технического специалиста по добавлению виджета на сайт (само добавление занимает около 5 минут). <br>Если вы по каким-либо причинам не смогли этого сделать, то отправьте запрос для настройки системы на электронный адрес info@bookingeasy.ru или позвоните по номеру телефона +79107948484. Специалисты компании BookingEasy помогут с внедрением."
        },
        {
          "title": "Как администратор узнает о новом бронировании",
          "text": "Администратор получит уведомление в телеграм от нашего бота, подключить к боту можно неограниченное количество аккаунтов"
        },
        {
          "title": "Как выглядит личный кабинет",
          "text": "Вы можете посмотреть личный кабинет, нажав \"Вход/Регистрация\" вверху или внизу сайта и введя логин и пароль demo"
        },
        {
          "title": "Как происходит оплата",
          "text": "Оплата производится в личном кабинете в разделе оплаты. Списание происходит в первой половине месяца за предыдущий месяц. Первый месяц после подключения бесплатно. Оплата рассчитывается по количеству подключенных групп объектов(например саун). Также оплата рассчитывается по вашему балансу, если на счету было 9900, то активируется тариф 825 р/месяц на 12 месяцев, если на балансе минимум будет списываться по 990."
        },
        {
          "title": "Можно ли добавить модуль в конструкторе сайтов Tilda",
          "text": "Да в конструкторе сайтов Тильда и других есть возможность вставить наш виджет. В Тильде для этого нужно в меню выбрать \"Другое\" потом \"HTML-код\" и туда вставить код находящийся в личном кабинете"
        },
        {
          "title":"Как можно добавить модуль в Вконтакте",
          "text":'Можно добавить ссылку, либо кнопку как в нашей группе <a href="https://vk.com/bookingeasy">https://vk.com/bookingeasy</a> (чтобы воспользоваться надо быть авторизированным в VK.)'
        }
      ],
      faqIndex: -1
    }
  },

  mounted() {
    global.value.lk = false;
    setTimeout(() => {
      this.rotateQuote()
    }, 3000);
  },

  methods: {
    rotateQuote() {
      this.quoteIndex++;
      if (this.quoteIndex > this.quotes.length - 1) this.quoteIndex = 0;
      this.quote = "";
      setTimeout(() => {
        this.quote = this.quotes[this.quoteIndex];
      }, 1500)
      setTimeout(() => {
        this.rotateQuote()
      }, 4000);
    },
    sendContact() {
      if (!this.formFields.agreeValue) {
        this.formFields.error = "Нужно согласиться с обработкой персональных данных";
        return;
      }
      if (!this.formFields.name) {
        this.formFields.error = "Не заполнено поле имя";
        return;
      }
      if (!this.formFields.email) {
        this.formFields.error = "Не заполнено поле email или телефон";
        return;
      }
      if (!this.formFields.text) {
        this.formFields.error = "Не заполнено поле текст";
        return;
      }

      this.loading = true;
      this.formFields.error = "";
      axios
          .post(global.value.url + "contact", this.formFields)
          .then(response => {
            this.result = response.data;
            this.errored = false;
            this.formFields.topic = "";
            this.formFields.text = "";
            this.formFields.error = "Запрос отправлен";
          })
          .catch(error => {
            console.log(error);
            this.errored = true;
          })
          .finally(() => (this.loading = false));
    },
    logout() {
      axios.get(global.value.url + "logout").then(() => {
        global.value.authenticated = false;
        global.value.lk = false;
      });
    }
  }
}
</script>
